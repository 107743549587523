import useForm from './useForm.js';
import React, { useState, useCallback, useEffect } from "react";
import validate from "../../utils/util-validator";
import ruleSet from './ruleSet.js';
import { navigate, Link } from "gatsby";
import { GoogleReCaptchaProvider, withGoogleReCaptcha } from 'react-google-recaptcha-v3';


// Style Imports
import "./Form.scss"
// Utility Imports
import { getVisitorId, getApiEndpoint } from '@src/helper';


const ContactUsForm = ({ googleReCaptchaProps }) => {
    const [submitting, setSubmitting] = useState(false);

    const getToken = useCallback(async () => {
        if (!googleReCaptchaProps.executeRecaptcha) {
            console.log('Recaptcha has not been loaded');
            return;
        }
        const _token = await googleReCaptchaProps.executeRecaptcha("ContactForm");
        return _token;
    }, [googleReCaptchaProps]);

    const success = useCallback((vals) => {
        fetch(getApiEndpoint() + 'allinforln/contact', {
            method: 'POST',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(vals),
        })
        .then(async (resp) => {
            var r = await resp.json();
            if (r.success) {
                gtmFormSuccess()
                gtmFormFieldCompletionSubmitted()
                navigate('/contact-us-confirmed',
                    {
                        state: {
                            success: true
                        }
                    });
            } else {
                navigate('/500');
            }
        })
        .catch(() => {
            navigate('/500');
        })
    }, [])

    //GTM


    const gtmFormSuccess = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Form Submission',
            'Event_Category': 'Form Submissions',
            'Event_Action': 'ContactUs',
            'Event_Label': 'Complete'
        });
    }
    const gtmFormFieldError = (errors) => {
        if (errors) {
            for (var error in errors) {
                const selector = ".error--" + error;
                if (document.querySelector(selector)) {
                    const errorText = document.querySelector(selector).innerText;
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'Form Errors',
                        'Event_Category': 'Validation Errors',
                        'Event_Action': 'ContactUs',
                        'Event_Label': error + '/' + errorText
                    });
                }
            }
            setSubmitting(false);
        }
    }

    const gtmFieldCompletion = () => {
        document.querySelectorAll('.form--contact input, .form--contact select, .form--contact textarea').forEach(field => {
            if (!field.getAttribute('data-has-ga-listener')) {
                field.setAttribute('data-has-ga-listener', 'true')
                field.addEventListener('change', fieldCompletionEvent);
            }
        });
    }

    const fieldCompletionEvent = function (e) {
        const field = e.target;
        let fieldInfo;
        switch (field.type) {
            case ('radio'):
                fieldInfo = (field.getAttribute('gaVal')) ? (field.name + '_' + field.getAttribute('gaVal')) : 'No gaVal found';
                break;
            case ('checkbox'):
                fieldInfo = field.name + '_' + field.checked;
                break;
            case ('select-one'):
                fieldInfo = field.name + '_' + field.value;
                break;
            default:
                fieldInfo = field.name
                
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Field Completion',
            'Event_Category': 'Form Field Completion',
            'Event_Action': 'ContactUs',
            'Event_Label': fieldInfo
        });
    }

    const gtmFormFieldCompletionSubmitted = () => {
        document.querySelectorAll('.form--contact input, .form--contact select, .form--contact textarea').forEach(field => {
            let fieldInfo;

            if ((field.type === 'radio' || field.type === 'checkbox') && !field.checked) return;
            if (field.value.length === 0) return;

            switch (field.type) {
                case ('radio'):
                    fieldInfo = (field.getAttribute('gaVal')) ? (field.name + '_' + field.getAttribute('gaVal')) : 'No gaVal found';
                    break;
                case ('checkbox'):
                    fieldInfo = field.name + '_' + field.checked;
                    break;
                case ('select-one'):
                        fieldInfo = field.name + '_' + field.value;
                        break;
                default:
                    fieldInfo = field.name
            }
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'Form Field Completion_Form Submitted',
                'Event_Category': 'Form Field Completion_Form Submitted',
                'Event_Action': 'ContactUs',
                'Event_Label': fieldInfo
            });
        })
    }

    useEffect(() => {
        gtmFieldCompletion();
        if (!values.interestedIn) {
            values.interestedIn = document.getElementById('interestedIn').value;
        }
        if (!values.message) {
            values.message = document.getElementById('message').value;
        }
    })

    const [{
        values,
        errors,
        handleChange,
        handleSubmit,
    }] = useForm(success, validate, ruleSet.contact.rules, { source: 'aurinia-allinforln-redesign-contact', visitorId: getVisitorId(), optin: true }, () => { }, gtmFormFieldError);
    return (
        <section id='Contact' className='bg-light-purple'>
            <div className="container container--narrow">
                <h1 className='text-left'>Contact Us</h1>
                <h2 className='text-left'>Get in touch with us</h2>
                <p className='text-left text--black'><strong>Interested in learning more about ALL IN&reg;? We'd love to hear from you.</strong></p>
                <form className="form--contact" onSubmit={(e)=>{e.preventDefault(); getToken().then((token)=>{handleSubmit(e, {captchaResponse: token})}); setSubmitting(true);}}>
                    <div className="container--split">
                        <div className='split-block--50 custom-split-block--50'>
                            <div className="single-col-input">
                                <label>
                                    First Name*
                                    <input aria-required="true" type="text" name="first_name" id="first_name" value={values.first_name || ''} onChange={handleChange} aria-invalid={errors?.first_name ? true : false} aria-labelledby={errors?.first_name ? "errorFname" : null} placeholder="First name" maxLength={'50'} />
                                </label>
                                {errors?.first_name && (
                                    <p id="errorFname" className="error error--first_name">Please enter your first name</p>
                                )}
                                <label>
                                    Last Name*
                                    <input aria-required="true" type="text" name="last_name" id="last_name" value={values.last_name || ''} onChange={handleChange} aria-invalid={errors?.last_name ? true : false} aria-labelledby={errors?.last_name ? "errorLname" : null} placeholder="Last name" maxLength={'50'} />
                                </label>
                                {errors?.last_name && (
                                    <p id="errorLname" className="error error--last_name">Please enter your last name</p>
                                )}
                                <label>
                                    Email Address*
                                    <input aria-required="true" type="text" name="email" id="email" placeholder="e.g. jsmith@email.com" onChange={handleChange} aria-invalid={errors?.email ? true : false} aria-labelledby={errors?.email ? "errorEmail" : null} maxLength={'50'} />
                                </label>
                                {errors?.email && (
                                    <p id="errorEmail" className="error error--email">Please input a valid email address</p>
                                )}
                            </div>
                            <div className="single-col-input address-input">
                                <label>
                                    Interested In
                                    <select name="interestedIn" id="interestedIn" value={values.interestedIn || 'General Inquiries'} onChange={handleChange} aria-invalid={errors?.interestedIn ? true : false}>
                                        <option selected="selected" value="General Inquiries">General Inquiries</option>
                                        <option value="Community Support">Community Support</option>
                                        <option value="Registration">Registration</option>
                                        <option value="ALL IN Newsletter">ALL IN&reg; Newsletter</option>
                                        <option value="Opt-out of Build Your Story">Opt-out of Build Your Story</option>
                                    </select>
                                </label>
                            </div>
                            <div className="single-col-input custom-single-col-input">
                                <label>
                                    Message
                                    <textarea type="text" name="message" id="message" cols="500" value={values.message || ''} onChange={handleChange} aria-invalid={errors?.message ? true : false} placeholder="Type message here..." maxLength={'1000'}></textarea>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="agreement"><p>By clicking Send a Message, I acknowledge that I voluntarily provide the information above which may include personal health information. I also certify that I am 18&nbsp;years of age or older and that I reside in the United States.</p></div>
                    <button type="submit" className={"cta--purple cta--body cta--submit" + (submitting ? " button--submitting" : "")}>Send a message</button>
                </form>
            </div>
        </section>
    )
}

const ContactUsFormWithCaptcha = withGoogleReCaptcha(ContactUsForm);
export default function ContactUsFormWrapper () {
    return(
        <GoogleReCaptchaProvider reCaptchaKey="6LcuTWwhAAAAABVfYC6XlGMwTPOwpyiRXKdc5X4O" >
            <ContactUsFormWithCaptcha/>
        </GoogleReCaptchaProvider>
    )
}
